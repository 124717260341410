import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container, UploadArea, PreviewContainer, FileContainer, ErrorContainer, Modal, Overlay, ModalButtonContainer } from './styles';
import { FaSearch, FaTrashAlt, FaSearchPlus, FaSearchMinus, FaUndo, FaTimes } from 'react-icons/fa';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

interface ImageSelectorProps {
  label: string;
  selectedFiles: File[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  clearPredictions: () => void;
  children?: any;
  showPreview?: boolean;
}

export const ImageSelector: React.FC<ImageSelectorProps> = ({
  label,
  selectedFiles,
  setSelectedFiles,
  clearPredictions,
  children,
  showPreview = true,
}) => {
  const [fileErrors, setFileErrors] = useState<{ file: File; error: string }[]>([]);
  const [modalFile, setModalFile] = useState<File | null>(null);
  const MAX_IMAGES = 1;

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: any[]) => {
    const newFiles = [...selectedFiles, ...acceptedFiles];
    if (newFiles.length > MAX_IMAGES) {
      alert(`Maximum number of images exceeded. Only ${MAX_IMAGES} images allowed.`);
      const allowedFiles = newFiles.slice(0, MAX_IMAGES);
      setSelectedFiles(allowedFiles);
      setFileErrors([...fileErrors, ...fileRejections.map((rejection) => ({
        file: rejection.file,
        error: 'Maximum number of images exceeded.',
      }))]);
    } else {
      setSelectedFiles(newFiles);
      setFileErrors([...fileErrors, ...fileRejections.map((rejection) => ({
        file: rejection.file,
        error: rejection.errors[0].message,
      }))]);
    }
  }, [selectedFiles, setSelectedFiles, fileErrors]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
    maxSize: 10 * 1024 * 1024,
  });

  const removeFile = (file: File) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    clearPredictions(); // Limpe as previsões ao remover o arquivo
  };

  const removeErrorFile = (file: File) => {
    setFileErrors((prevErrors) => prevErrors.filter((error) => error.file !== file));
  };

  const openModal = (file: File) => {
    setModalFile(file);
  };

  const closeModal = () => {
    setModalFile(null);
  };

  return (
    <Container>
      <label>{label}</label>
      <PreviewContainer>
        {selectedFiles.map((file, index) => (
          <FileContainer key={index}>
            <div className="file-name">{file.name}</div>
            <img
              src={URL.createObjectURL(file)}
              alt="Preview"
              style={{ maxWidth: '320px', maxHeight: '225px', display: 'block' }}
            />
            <div className="button-container">
              <button onClick={() => openModal(file)}><FaSearch /></button>
              <button onClick={() => removeFile(file)}><FaTrashAlt /></button>
            </div>
          </FileContainer>
        ))}
        {fileErrors.map((errorItem, index) => (
          <ErrorContainer key={index}>
            <span>{errorItem.file.name}</span>
            <span className="error-message">{errorItem.error}</span>
            <button onClick={() => removeErrorFile(errorItem.file)}>
              <FaTrashAlt />
            </button>
          </ErrorContainer>
        ))}
        {selectedFiles.length < MAX_IMAGES && (
          <UploadArea {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Choose files or drag & drop here</p>
          </UploadArea>
        )}
      </PreviewContainer>

      {modalFile && (
        <Overlay onClick={closeModal}>
          <Modal onClick={(e) => e.stopPropagation()}>
            <TransformWrapper initialScale={1}>
              {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                  <TransformComponent>
                    <img
                      src={URL.createObjectURL(modalFile)}
                      alt="Modal Preview"
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  </TransformComponent>
                  <ModalButtonContainer>
                    <button onClick={() => zoomIn()}><FaSearchPlus /></button>
                    <button onClick={() => zoomOut()}><FaSearchMinus /></button>
                    <button onClick={() => resetTransform()}><FaUndo /></button>
                    <button 
                      onClick={() => closeModal()}
                      style={{ color: 'red', display: 'flex', top: '0', right: '0', position: 'absolute' }}>
                        <FaTimes />
                    </button>
                  </ModalButtonContainer>
                </>
              )}
            </TransformWrapper>
          </Modal>
        </Overlay>
      )}
    </Container>
  );
};
