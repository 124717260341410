import styled, { css } from 'styled-components';

interface ContainerProps {}

export const Container = styled.table<ContainerProps>`
  width: 100%;
  max-width: 320px;  // Define um max-width para limitar o tamanho da tabela
  margin: 0 auto;  // Centraliza a tabela horizontalmente
  border-collapse: collapse;
  th {
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors['red-700']};
    text-transform: uppercase;
    height: 4rem;
  }
  td {
    font-weight: 500;
    font-size: 0.75rem;
    padding: 1rem 1rem;
  }
  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.colors['gray-100']};
  }
  tbody {
    tr:hover {
      background-color: ${({ theme }) => theme.colors['red-100']};
    }
  }
  th,
  td {
    border-bottom: 2px solid;
    border-color: ${({ theme }) => theme.colors['red-700']};
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
