import styled from 'styled-components';

interface ContainerProps {}

export const Container = styled.section<ContainerProps>`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  min-height: 50rem;

  label {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 33.6px;
    margin: 2rem 0;
  }
`;

export const Content = styled.div`
  max-width: 1016px;
  flex: 1;
  flex-direction: column;
  margin: 3.5rem 1rem;

  .main-content {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }

  .image-section {
    flex: 1;
    max-width: 60%;
  }

  .table-section {
    flex: 1;
    max-width: 40%;
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 110px;
  }
  .actions {
    margin-top: 2rem;
    margin-bottom: -2rem;
  }
  .button-abstract {
    display: flex;
    align-items: center;
    gap: 1rem;

    svg {
      font-size: 2rem;
    }
  }

  .box-abstract {
    height: auto;
    opacity: 1;
    transition: 0.3s ease all;
    margin-top: 2.5rem;
    margin-bottom: -2rem;

    span {
      display: flex;
      margin-top: 24px;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: ${({ theme }) => theme.colors['gray-900']};
    }
  }
  .text-secondary {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors['gray-700']};
  }
`;
