import React from 'react';
import { Route, Routes as RoutesDom } from 'react-router-dom';
import { Publications } from '../Pages/Publications';
import { Team } from '../Pages/Team';
import { Home } from '../Pages/Home';
import { Layout } from '../Pages/Layout';
import { PageNews } from '../Pages/News';
import { ViewPost } from '../Pages/ViewPost';
import { PageTools } from '../Pages/Tools';

import { ToolClassifier } from '../components/ToolClassifier';

export const Routers: React.FC = () => (
  <RoutesDom>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />

      <Route path="tools" element={<PageTools />} />

      <Route path="tools/classifier" element={<ToolClassifier />} /> {/* "test/cs/oz" */}

      <Route path="news" element={<PageNews />} />

      <Route path="news/post" element={<ViewPost />} />

      <Route path="publications" element={<Publications />} />

      <Route path="team" element={<Team />} />
    </Route>
  </RoutesDom>
);
